import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import loginReducer from './login';
import dashboardMenuReducer from './dashboardMenu';
import saveReducer from './saveSlice';
import researchReducer from './researchSlice';
import jobCenterReducer from './jobCenterSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['loginUserData', 'dashboardMenuData', 'saveStatus', 'researchData', 'jobCenterData'], // The reducers you want to persist
};

const rootReducer = combineReducers({
  loginUserData: loginReducer,
  dashboardMenuData: dashboardMenuReducer,
  saveStatus: saveReducer,
  researchData: researchReducer,
  jobCenterData: jobCenterReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
